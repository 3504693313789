<template>
    <div>
        <div align="center">
            <h4>Problemi segnalati sulla pratica</h4>
            <hr>
        </div>

        <div class="row justify-center">

            <div class="col-12 col-md-5 q-px-md">
                <h5 class="q-mt-sm">Dati della pratica</h5>
                <q-list separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Cliente:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.name}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Stato della pratica:</q-item-label>
                            <q-item-label caption>{{getStatoPratica}}</q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-item-label>Unità operativa richiedente:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.unita_operativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Persona operativa:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.operatore}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Numero pratica:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.numero}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Numero interno:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.numero_interno}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Data di decorrenza richiesta:</q-item-label>
                            <q-item-label caption>{{getDataDecorrenzaPratica}}</q-item-label>
                        </q-item-section>

                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Note:</q-item-label>
                            <q-item-label caption>{{annotazione}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>

            </div>

            <div class="col-12 col-md-7">
                <h5 class="q-mt-sm">Elenco dei problemi</h5>

                <div v-show="!isProblemiPresenti">
                    <h5>Nessun problema &egrave; stato identificato per questa pratica.</h5>
                </div>

                <q-list separator bordered>
                    <q-item
                        v-for="(problema,index) in elenco_problemi" v-bind:key="index"
                        clickable v-ripple
                         @click.native="onSelezionaProblema(problema)">
                        <q-item-section>
                            <q-item-label>Descrizione problema:</q-item-label>
                            <q-item-label caption>{{problema.note}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Segnalato da:</q-item-label>
                            <q-item-label caption>{{problema.personaoperativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Data segnalazione:</q-item-label>
                            <q-item-label caption>{{getDataProblema(problema.segnalato_at)}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Problema risolto:</q-item-label>
                            <q-item-label caption>{{isProblemaRisolto(problema)}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Data risoluzione problema:</q-item-label>
                            <q-item-label caption>{{getDataProblema(problema.risolto_at)}}</q-item-label>
                        </q-item-section>
                        <q-item-section avatar>
                            <q-avatar v-show="!problema.is_risolto" color="orange-3" text-color="black" icon="mdi-download" />
                            <q-avatar v-show="problema.is_risolto"  color="orange-3" text-color="black" icon="mdi-check-outline"  size="36px" />
                        </q-item-section>

                    </q-item>
                </q-list>
            </div>

            <div class="col-12 col-md-12">
                <br><hr>
            </div>

            <div class="col-12 col-md-12" align="center">

                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onClickIndietro()"
                />

            </div>
        </div>
        <br><br><br><br><br>
    </div>
</template>

<script>
    import { mapFields} from 'vuex-map-fields';
    import { mapState,mapActions } from 'vuex';
    import QQButton from "@/components/QQButton.vue";
    import commonLib from "@/libs/commonLib";
    import api from "@/libs/api";

    export default {
        name: "ProblemiSegnalati",
        components: {
            QQButton
        },
        data() {
            return {
                elenco_problemi: [],
                annotazione: ""
            }
        },
        methods: {
            ...mapActions({
                fetchProblemiPratica: "gestionePratiche/fetchProblemiPratica",
                fetchNotePratica: "gestionePratiche/fetchNotePratica",
                fetchDatiPratica: "gestionePratiche/fetchDatiPratica",
                numero_pratica: "gestionePratiche/numero_pratica"
            }),
            isProblemaRisolto(problema) {
                if (problema.is_risolto) return "Si";

                return "No";
            },
            getDataProblema(data) {
                if (commonLib.isEmpty(data)) return "";

                let MiaData = (data+"").substring(0,10);
                let element = MiaData.split("-");
                return element[2]+"/"+element[1]+"/"+element[0];
            },
            onClickIndietro() {
                this.$router.push({name : "Pratiche.MenuGestionePratiche"});
            },
            async onSelezionaProblema(problema) {

                let problema_risolto = problema.is_risolto;

                if (!problema_risolto) {
                    this.$q.dialog({
                        cancel: {
                            focus: true,
                            flat: false,
                            label: "No, neanche un po`",
                            color: "primary"
                        },
                        ok: {
                            color: "secondary",
                            flat: false,
                            label: "Si, lo sono"
                        },
                        title: 'Attenzione',
                        message: 'Sei sicuro di aver risolto questo problema?',
                    }).onOk(async () => {
                        let IDPratica = problema.idpratica;
                        let IDProblema = problema.idproblema;
                        var numero_pratica = this.formPratica.preventivo.id;
                        let url = process.env.VUE_APP_API_URL + "pratiche/"+IDPratica+"/risolviProblema/"+IDProblema;

                        await api.postJSonToController(url,{ idpratica: IDPratica, idproblema: IDProblema });

                        this.elenco_problemi = await this.fetchProblemiPratica(numero_pratica);
                        await this.fetchDatiPratica({ numero_pratica: numero_pratica, token: "" });
                    });

                } else {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Il problema selezionato risulta gia risolto in data '+this.getDataProblema(problema.risolto_at),
                    });
                }
            }
        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPratica: state => state.gestionePratiche.formPratica
            }),
            ...mapFields("gestionePratiche", {
                RecordSelezionato: "RecordSelezionato"
            }),
            isProblemiPresenti() {
                if (commonLib.isEmpty(this.elenco_problemi)) return false;
                return (this.elenco_problemi.length > 0);
            },
            getStatoPratica() {
                if (commonLib.isEmpty(this.formPratica)) return "";
                return this.formPratica.stato_pratica.toUpperCase();
            },
            getDataDecorrenzaPratica() {
                if (commonLib.isEmpty(this.formPratica)) return "";
                return this.formPratica.preventivo.decorrenza;
            }
        /*    getFrazionamentoPratica() {
                if (commonLib.isEmpty(this.formPratica)) return "";
                //return this.formPratica.preventivo.frazionamento.toUpperCase();

                return "";
            }*/
        },
        async mounted() {
            this.annotazione = "";
            var numero_pratica = this.formPratica.preventivo.id;

            if (!commonLib.isEmpty(this.formPratica)) {
                this.annotazione = await this.fetchNotePratica(numero_pratica);
                this.elenco_problemi = await this.fetchProblemiPratica(numero_pratica);
            }
        },
        async activated() {
            this.annotazione = "";
            var numero_pratica = this.formPratica.preventivo.id;

            if (!commonLib.isEmpty(this.formPratica)) {
                this.annotazione = await this.fetchNotePratica(numero_pratica);
                this.elenco_problemi = await this.fetchProblemiPratica(numero_pratica);
            }
        }
    }
</script>
